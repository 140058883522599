.container {
  width: 100%;
  height: 100%;
  z-index: 999;
  right: 0;
  top: 0;

  transition: all 150ms ease;
}

.minimize {
  width: 370px;
  height: 230px !important;
  right: 20px;
  top: calc(100% - 250px) !important;
  overflow: hidden;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}