[theme="dark"] {
  input::selection {
    background: #fff;
  }
  input::-moz-selection {
    background: #fff;
  }
  .ant-switch:not(.ant-switch-checked){
    background-color: var(--theme_color);
  }
  .ui.checkbox{
    label{
      color: var(--theme_text_color) !important;
    }
  }
  div {
    .ant-select-arrow,
    .ant-select-selection,
    input[type=email], input[type=password], input[type=search], input[type=text]:not(#filteringInput){
      color: white !important;
      background-color: black;
      box-shadow: none;
    }
  }
  .ui.pagination.menu {
    .item{
      background-color: var(--theme_background_color);
      color: var(--theme_text_color);
      &.disabled {
        background-color: var(--theme_background_color) !important;
      }
      &.active {
        background-color: #fff;
        color: black;
      }
    }
  }
  div.searchPanelBackground{
    background: linear-gradient(to bottom, rgb(19, 19, 19) 1%, rgb(35, 38, 39) 100%);
    a.searchPanelBackground{
      .searchPanelText{
        color: var(--theme_text_color);;
      }
    }
  }
  .singlePageBreadcrumbs{
    li{
      a,span{
        &:hover{
          color: white !important;
        }
      }
      &:last-child{
        a,span{
          color: white !important;
        }
      }
    }
  }
  .vis-group{
    background-color: #FAFAFA;
  }

}