.commonStyle {
    background-color: var(--theme_background_color) !important;
    outline: none;
    font-size: 18px;
    min-width: 135px;
    color: var(--theme_text_color);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    text-transform: capitalize;
    cursor: pointer;
}

.commonButton {
    @extend .commonStyle;
    padding: 5px;
    width: 100%;
    border: none;
}

.controlButton {
    @extend .commonStyle;
    border-radius: 4px;
    border: 2px solid var(--theme_text_color);
    padding: 8px;
    line-height: initial;

    &:hover, &:focus {
        border: 2px solid var(--theme_selection_color);
    }
}