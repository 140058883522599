:root {
	--theme_background_color: #eeeeee;
	--theme_color: #eeeeee;
	--theme_selection_color: #0fa0db;
	--theme_text_color: #000000;
	--theme_reverse_white: #ffffff;
	--theme_reverse_dark: #000000;
	--theme_background_secondary: #fafafa;
	--theme_background_modal: 237, 237, 237;
	--theme_placeholder: 0 0 0;
}

:root[theme="dark"] {
	--theme_background_color: #222222 !important;
	--theme_background_secondary: #2a2727 !important;
	--theme_color: #eeeeee !important;
	--theme_selection_color: #0fa0db !important;
	--theme_text_color: #eeeeee !important;
	--theme_reverse_white: #000000;
	--theme_reverse_dark: #eeeeee;
	--theme_background_modal: 34, 34, 34;
	--theme_placeholder: 255 255 255;
}
