html[dir='rtl'].direct-right {
  #controlsWrapper,
  .modals.dimmer{
    direction: ltr !important;
  }
  .slick-next, .slick-prev{
    transform: scale(1.5) rotate(180deg);
  }
  
  .liveTvVideoWrapper  {
    .slick-next, .slick-prev{
      transform: translate(0,-50%);
    }
    .slick-next {
      left: -60px;
      right: auto;
    }
    .slick-prev{
      right: -60px;
      left: auto;
    }
  }

  .slick-slide {
    float: left;
  }
  .activeFilter{
    svg.clearText{
      left: -30px !important;
      right: auto !important;
    }
    .searchPanelBackground{
      img{
        margin-left: 10px;
      }
    }
  }
  .singlePageBreadcrumbs{
    li{
      &:first-child{
        margin-right: 0;
      }
      &:not(:last-child)::after{
        right: auto;
        left: -23px;
      }
    }
  }
  .support-rtl{
    float: right;
    margin-right: 2rem;
  }
  .ui.checkbox {
    input.hidden+label{
      margin-right: 1rem;
    }
  }
  .ant-notification-notice-close{
    right: auto;
    left: 22px;
  }
  .titleYearDescription{
    margin-right: auto;
    margin-left: 15px;
    &::after{
      right: auto;
      left: -8px;
    }
  }
  .ant-select{
    .ant-select-selection-selected-value{
      float: right;
      padding-right: 0;
    }
    .ant-select-arrow{
      left: 11px;
      right: auto;
    }
  }
  .ant-select-dropdown-menu{
    max-width: 100%;
  }
  .ant-select-dropdown-menu-item{
    padding: 5px 1px;
  }
  @media (max-width: 768px){
    .liveTvVideoWrapper,
    #mobile-nav,
    .slick-slider{
      direction: ltr !important;
    }
  }

}
