@mixin fadeInMoveLeft {
	opacity: 1;
	transform: translateX(0);
}

@mixin fadeOutMoveLeft {
	opacity: 0;
	transform: translateX(30px);
}

@keyframes moveLeft {
	0% {
		@include fadeOutMoveLeft;
	}
	100% {
		@include fadeInMoveLeft;
	}
}

@keyframes moveLeftReverse {
	0% {
		@include fadeInMoveLeft;
	}
	100% {
		@include fadeOutMoveLeft;
	}
}

@mixin fadeInMoveRight {
	opacity: 1;
	transform: translateX(0);
}

@mixin fadeOutMoveRight {
	opacity: 0;
	transform: translateX(-30px);
}

@keyframes moveRight {
	0% {
		@include fadeOutMoveRight;
	}
	100% {
		@include fadeInMoveRight;
	}
}

@keyframes moveRightReverse {
	0% {
		opacity: 1;
		transform: translateX(0);
	}
	100% {
		opacity: 0;
		transform: translateX(-20px);
	}
}

.leftPart {
	animation: moveRight 500ms ease forwards;
}

.leftPartReverse {
	animation: moveRightReverse 500ms ease forwards;
}

.rightPartAnimationReverse {
	animation: moveLeftReverse 500ms ease forwards;
}

.rightPartAnimation {
	animation: moveLeft 500ms ease forwards;
}

@keyframes moveTop {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}