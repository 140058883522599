.wrapper {
  width: 100%;
  height: 100%;
  background-color: black;
  position: relative;
  animation: fade 250ms ease;
  display: flex;
  flex-direction: column;

  iframe {
    pointer-events: none;
  }

  .reactPlayer {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;

    & > video {
      width: 100%;
      height: auto !important;
      max-height: 100% !important;

      &::-webkit-media-text-track-container,
      &::cue {
        display: block !important;
      }
    }
  }
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}