@import "~react-calendar-timeline/lib/lib/Timeline.css";
@import "~hamburgers/dist/hamburgers.min.css";
@import "colors.css";
@import "rtl.scss";
@import "dark-theme.scss";

*{
    font-family: 'Roboto', sans-serif !important;
    @media (max-width:768px){
      user-select: none !important;
      -webkit-user-select: none;
      -ms-user-select: none;
      -moz-user-select: none !important;
    }
}

html, body{
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: var(--theme_background_color) !important;
    overflow-x: hidden;
    color: var(--theme_text_color);
}

body > div#app{
    width: 100%;
    height: 100%;
    filter: blur(0px);
    transition: filter 500ms ease;
    @-moz-document url-prefix() {
      filter: none;
    }
}

video::-webkit-media-controls {
  display: none !important;
}

video::-moz-media-controls {
  display: none !important;
}

::cue {
  text-align: center;
}

.ant-notification-notice-icon, .anticon-close{
  &::before, &::after{
    content: '';
  }
}

.dimmer{
  background: rgba(255,255,255,0.5) !important;
}

.page.modals.dimmer{
  align-items: center;
  justify-content: center;
  .modal{
    top: auto;
  }
}

.dimmer.blurring{
  background: rgba(255,255,255,0.5) !important;
}

.blurring.dimmed.dimmable>:not(.dimmer){
  filter: blur(10px) grayscale(0);
}

body.dimmable.dimmed.blurring{
  #app, #fixed-nav{
    filter: blur(0px);
  }
  @media (min-width: 768px){
    #app, #fixed-nav{
      filter: blur(10px);
    }
  }
}

.topPadding{
  padding-top: 50px;
}

#mobile-nav{
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: fixed;
  z-index: 999;
  pointer-events: none;
}

#modal-root, #video-root {
  top: 0;
  position: fixed;
  height: 100vh;
  z-index: 999;
}

#modal-root{
  z-index: 1000;
}

.slick-disabled{
  display: none !important;
}

body.animating.in.dimmable, body.dimmed.dimmable {
  overflow: auto;
}

input,
input:before,
input:after,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
  box-shadow: 0 0 0 30px white inset;
}

h1, h2, h3, h4, h5, h6{
  color: var(--theme_text_color);
}

.hamburger-inner, .hamburger-inner:after, .hamburger-inner:before{
  background-color: var(--theme_text_color);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}